import axios from 'axios';
import utilsService from './utils.service';

/**
 * PeriodReportSummary API Service.
 * Communication interface for the TimeReport REST API.
 */
export default {
    getManagerPeriodReportSummaries,
    getUserPeriodReportSummary,
    getHumanResourcesPeriodReportSummaries,
};

const timeReportBaseUrl = `${process.env.VUE_APP_TIME_REPORT_API_BASE_URL}/period_reports`;

/**
 * Returns a summary list of the user's time reports.
 *
 * @param {String} userGid        - The user gid used.
 * @param {String} yearMonthStart - Start date of the period.
 * @param {String} yearMonthEnd   - End date of the period.
 * @returns {Promise<Array<Object>>}
 */
async function getUserPeriodReportSummary(userGid, yearMonthStart, yearMonthEnd) {
    return axios
        .get(
            `${timeReportBaseUrl}/${userGid}?year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}`
        )
        .then((result) => utilsService.fromSnakeCaseToCamelCase(result.data));
}

/**
 * Returns a summary list of time reports of users filtered by manager Gid
 *
 * @param {String} managerGid     - The manager gid used to get list of summary time report.
 * @param {String} yearMonthStart - Start year-month (yyyy-mm) of period
 * @param {String} yearMonthEnd   - End year-month (yyyy-mm) of period
 * @param {String} nameFilter     - Filter by name, default value: ''.
 * @param {Integer} pageSize      - Number of elements in page.
 * @param {Integer} page          - Number of the current page.
 * @returns {Promise<Array<Object>>}
 */
async function getManagerPeriodReportSummaries(
    managerGid,
    yearMonthStart,
    yearMonthEnd,
    nameFilter,
    pageSize = 20,
    page = 1
) {
    return getPeriodReportSummaries(
        managerGid,
        yearMonthStart,
        yearMonthEnd,
        nameFilter,
        pageSize,
        page,
        'manager'
    );
}

/**
 * Returns a summary list of time reports of human resources Referees.
 *
 * @param {String} userGid        - The user gid used.
 * @param {String} yearMonthStart - Start date of the period.
 * @param {String} yearMonthEnd   - End date of the period.
 * @param {String} nameFilter     - Filter by name, default value: ''.
 * @param {Integer} pageSize      - Number of elements in page.
 * @param {Integer} page          - Number of the current page.
 * @returns {Promise<Object>}
 */
async function getHumanResourcesPeriodReportSummaries(
    userGid,
    yearMonthStart,
    yearMonthEnd,
    nameFilter,
    pageSize = 20,
    page = 1
) {
    return getPeriodReportSummaries(
        userGid,
        yearMonthStart,
        yearMonthEnd,
        nameFilter,
        pageSize,
        page,
        'hr'
    );
}

async function getPeriodReportSummaries(
    userGid,
    yearMonthStart,
    yearMonthEnd,
    nameFilter,
    pageSize = 20,
    page = 1,
    role
) {
    const filter = nameFilter ? nameFilter.trim().toUpperCase() : '';

    return axios
        .get(
            `${timeReportBaseUrl}?${role}=${userGid}&year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}&name_filter=${filter}&page_size=${pageSize}&page=${page}`
        )
        .then((result) => {
            const elements = result.data.elements.map((data) =>
                utilsService.fromSnakeCaseToCamelCase(data)
            );
            return {
                hasMore: result.data.has_more,
                hasPrevious: result.data.has_previous,
                pageSize: result.data.page_size,
                currentPage: result.data.current_page,
                totalPage: result.data.total_pages,
                userPeriodReportSummaryArray: elements,
            };
        });
}
