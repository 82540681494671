<template>
    <v-date-picker
        id="calendar-picker"
        full-width
        no-title
        locale="fr-FR"
        first-day-of-week="1"
        multiple
        v-model="workedDays"
        :show-current="false"
        :picker-date="yearMonth"
        :readonly="readOnly"
        :min="minDate"
        :max="maxDate"
        :events="publicHolidayDates"
        event-color="red lighten-1"
        @click:date="onClick"
        @update:picker-date="onPickerUpdate"
    />
</template>

<script>
import moment from 'moment';
import colors from '@/styles/colors.scss';
import dateFormatConstants from '@/constants/date-format.constants';

export default {
    name: 'calendar-picker',

    props: {
        backgroundColor: {
            type: String,
            required: true,
        },
        publicHolidays: {
            type: Object,
            required: true,
        },
        monthWorkedDays: {
            type: Array,
            required: false,
            default: () => [],
        },
        monthHalfWorkedDays: {
            type: Array,
            required: false,
            default: () => [],
        },
        yearMonth: {
            type: String,
            required: false,
            default: () => '',
        },
        readOnly: {
            type: Boolean,
            required: true,
        },
        minDate: {
            type: String,
            required: false,
            default: null,
        },
        maxDate: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            workedDays: this.monthWorkedDays,
            halfDays: this.monthHalfWorkedDays,
        };
    },

    computed: {
        publicHolidayDates() {
            if (this.publicHolidays) {
                return Object.keys(this.publicHolidays).filter((el) =>
                    el.startsWith(this.yearMonth)
                );
            }
            return [];
        },
    },

    mounted() {
        this.setButtonsColor('white', this.backgroundColor);
        this.addHalfDaysAndHolidaysClass();
    },

    watch: {
        monthWorkedDays(newVal) {
            this.workedDays = newVal;
        },

        monthHalfWorkedDays(newVal) {
            this.halfDays = newVal;
        },

        halfDays() {
            this.addHalfDaysAndHolidaysClass();
        },

        backgroundColor(newVal, oldValue) {
            if (newVal !== oldValue) {
                this.setButtonsColor('white', this.backgroundColor);
            }
        },
    },

    methods: {
        onPickerUpdate(value) {
            if (this.yearMonth && value !== this.yearMonth) {
                this.$emit('year-month-change', value);
            }
        },
        onClick(day) {
            if (!this.readOnly) {
                if (
                    !this.monthWorkedDays.includes(day) &&
                    !this.monthHalfWorkedDays.includes(day)
                ) {
                    this.addHalfDay(day);
                    this.$emit('update:monthHalfWorkedDays', this.halfDays);
                } else {
                    this.removeHalfDay(day);
                    this.$emit('update:monthWorkedDays', this.workedDays);
                }
            }
        },

        addHalfDay(day) {
            if (this.workedDays.includes(day)) {
                const index = this.workedDays.indexOf(day);
                this.workedDays.splice(index, 1);
            }
            this.halfDays.push(day);
            this.addHalfDayClass(day);
        },
        removeHalfDay(day) {
            if (this.halfDays.includes(day)) {
                const index = this.halfDays.indexOf(day);
                this.halfDays.splice(index, 1);
                this.removeHalfDayClass(day);
            }
        },

        setButtonsColor(color, background) {
            const bodyStyles = document.body.style;
            bodyStyles.setProperty('--calendar-color', colors[color]);
            bodyStyles.setProperty('--calendar-background-color', colors[background]);
        },
        addHalfDayClass(dayDate) {
            const allDates = document.querySelectorAll(
                '.v-date-picker-table .v-btn .v-btn__content'
            );
            const dayNumber = parseInt(dayDate.split('-')[2], 10);

            allDates.forEach((x, i) => {
                if (parseInt(x.innerHTML, 10) === dayNumber) {
                    allDates[i].classList.add('half-day-button');
                    allDates[i].innerHTML = `<span class="half-day-text">${x.innerHTML}</span>`;
                }
            });
        },
        removeHalfDayClass(dayDate) {
            const allDates = document.querySelectorAll(
                '.v-date-picker-table .v-btn .v-btn__content .half-day-text'
            );
            const dayNumber = parseInt(dayDate.split('-')[2], 10);

            allDates.forEach((x, i) => {
                if (parseInt(x.innerHTML, 10) === dayNumber) {
                    const buttonElement = allDates[i].parentNode;
                    buttonElement.innerHTML = dayNumber;
                    buttonElement.classList.remove('half-day-button');
                }
            });
        },

        addHalfDaysAndHolidaysClass() {
            const allDates = document.querySelectorAll(
                '.v-date-picker-table .v-btn .v-btn__content'
            );
            const halfDays = this.halfDays.map((x) => parseInt(x.split('-')[2], 10));
            const holidays = this.publicHolidayDates.map((x) => parseInt(x.split('-')[2], 10));

            allDates.forEach((x, i) => {
                if (halfDays.includes(parseInt(x.innerHTML, 10))) {
                    allDates[i].classList.add('half-day-button');
                    allDates[i].innerHTML = `<span class="half-day-text">${x.innerHTML}</span>`;
                }
                if (!x.innerHTML.includes('holiday-label')) {
                    let dayNumber;
                    if (x.innerHTML.includes('</span>')) {
                        dayNumber = parseInt(x.innerHTML.split('</span>')[0].split('>')[1], 10);
                    } else {
                        dayNumber = parseInt(x.innerHTML, 10);
                    }
                    if (holidays.includes(dayNumber)) {
                        const todayDate = moment(
                            `${this.yearMonth}-${dayNumber}`,
                            'YYYY-MM-DD'
                        ).format(dateFormatConstants.DATE_FORMAT);
                        allDates[i].classList.add('holiday');
                        allDates[
                            i
                        ].innerHTML += `<span class="holiday-label"> ${this.publicHolidays[todayDate]} </span>`;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/styles/colors';

:root {
    --calendar-background-color: var(--v-accent-base);
    --calendar-color: #ffffff;
}

@media (min-width: 600px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 100%;
    }
}

#calendar-picker {
    background-size: contain;

    .v-date-picker-table {
        padding: 0 25px;
    }

    .v-date-picker-table .v-btn.v-btn--active {
        color: var(--calendar-color);
        background-color: var(--calendar-background-color) !important;
    }

    .v-date-picker-table .v-btn:before {
        background-color: transparent;
    }

    .half-day-button {
        width: inherit;
        height: inherit;
        display: inline-grid !important;
        background: linear-gradient(
            90deg,
            var(--calendar-background-color) 50%,
            var(--calendar-color) 50%
        );
        border-radius: inherit;
    }

    .half-day-text {
        background: linear-gradient(
            90deg,
            var(--calendar-color) 50%,
            var(--calendar-background-color) 50%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .v-date-picker-header {
        .v-picker__body {
            background: transparent;
        }

        button {
            text-transform: capitalize;
        }
    }

    .holiday {
        position: relative;
        .holiday-label {
            visibility: hidden;
            width: auto;
            -webkit-text-fill-color: initial;
            background-color: var(--v-accent-darken2);
            color: #fff;
            text-align: center;
            padding: 5px;
            border-radius: 6px;
            top: 20px;
            position: absolute;
            z-index: 1;
        }
    }
    .holiday:hover,
    .holiday:active {
        .holiday-label {
            visibility: visible;
        }
    }
}
</style>
