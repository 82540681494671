<template>
    <calendar-wrapper
        :name="userTimeReportInfo.name"
        :isValidated="isValidated"
        :isOverWorking="isOverWorking"
        :isUpdating="isUpdating"
        :readOnly="readOnly"
        :role="pageSpecificDetails.role"
        :monthWorkingDaysNumber="monthWorkingDaysNumber"
        :monthWorkedDaysNumber="monthWorkedDaysNumber"
        :formattedLastModificationDate="formattedLastModificationDate"
        @updateOrSaveTimeReport="updateOrSaveTimeReport()"
        @show-overview="onShowOverview()"
    >
        <month-summary
            :userTimeReportInfo="userTimeReportInfo"
            @year-month-change="onYearMonthChange"
        />

        <v-row justify="center">
            <v-col class="calendar" cols="12" sm="6">
                <calendar-picker
                    :backgroundColor="displayedColor"
                    :readOnly="readOnly"
                    :minDate="contractStartDate"
                    :maxDate="contractEndDate"
                    :yearMonth="yearMonth"
                    :monthWorkedDays.sync="monthWorkedDays"
                    :monthHalfWorkedDays.sync="monthHalfWorkedDays"
                    :publicHolidays="userTimeReportInfo.publicHolidays"
                    @year-month-change="onYearMonthChange"
                />
            </v-col>
        </v-row>

        <v-dialog v-model="isDialogDisplayed" persistent max-width="470">
            <v-card>
                <v-card-title class="headline">
                    Voulez-vous valider les modifications ?
                </v-card-title>
                <v-card-text>
                    Vous perdrez vos modifications si vous ne les validez pas.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="goToComingYearMonth()">Ne pas valider</v-btn>
                    <v-btn color="primary" text @click="validateByDialog()">Valider</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </calendar-wrapper>
</template>

<script>
import moment from 'moment';

import timeReportService from '@/services/time-report-api.service';
import utilsService from '@/services/utils.service';
import workedDaysService from '@/services/worked-days.service';

import dateFormatConstants from '@/constants/date-format.constants';
import messagesConstants from '@/constants/messages.constants';
import utilsConstants from '@/constants/utils.constants';

import CalendarPicker from '@/components/calendar/calendar-picker';
import MonthSummary from '@/components/calendar/month-summary';
import CalendarWrapper from '@/components/calendar/calendar-wrapper';

export default {
    name: 'worked-days-calendar',

    components: {
        CalendarPicker,
        MonthSummary,
        CalendarWrapper,
    },

    props: {
        readOnly: {
            type: Boolean,
            required: true,
        },
        pageSpecificDetails: {
            type: Object,
            required: true,
        },
        userTimeReportInfo: {
            type: Object,
            required: true,
        },
        receivedYearMonth: {
            type: String,
            required: true,
        },
        saveActualTimeReport: {
            type: Boolean,
            required: false,
            daulfaut: false,
        },
    },

    data() {
        return {
            yearMonth: '',
            comingYearMonth: '',

            monthWorkedDays: [],
            monthWorkingDays: [],
            monthHalfWorkedDays: [],
            timeReport: null,

            isDialogDisplayed: false,
        };
    },

    async created() {
        if (this.saveActualTimeReport && this.receivedYearMonth) {
            this.onSaveActualTimeReport(this.receivedYearMonth);
        } else {
            this.onYearMonthChange(this.receivedYearMonth);
        }
    },

    computed: {
        userGid() {
            return this.userTimeReportInfo.gid;
        },
        displayedColor() {
            return this.isValidated && !this.isUpdating
                ? this.pageSpecificDetails.backgroundColor.validated
                : this.pageSpecificDetails.backgroundColor.default;
        },

        isValidated() {
            return !!(this.timeReport && this.timeReport.id);
        },
        isUpdating() {
            if (this.isValidated && Array.isArray(this.timeReport.workedDays)) {
                if (this.timeReport.totalWorkedDays !== this.monthWorkedDaysNumber) {
                    return true;
                }

                return !utilsService.isArraysEqual(
                    this.userMonthWorkedDays,
                    this.timeReport.workedDays,
                    'date'
                );
            }
            return false;
        },
        isOverWorking() {
            return this.monthWorkedDaysNumber > this.monthWorkingDaysNumber;
        },

        monthWorkingDaysNumber() {
            return this.monthWorkingDays.length;
        },
        monthWorkedDaysNumber() {
            let number = 0;
            if (this.monthWorkedDays && this.monthWorkedDays.length > 0) {
                number += this.monthWorkedDays.length;
            }
            if (this.monthHalfWorkedDays && this.monthHalfWorkedDays.length > 0) {
                number += this.monthHalfWorkedDays.length / 2;
            }
            return number;
        },

        userMonthWorkedDays() {
            return this.userMonthFullWorkedDays.concat(this.userMonthHalfWorkedDays);
        },
        userMonthFullWorkedDays() {
            return this.monthWorkedDays.map((date) => {
                return {
                    date: moment(date).format(dateFormatConstants.DATE_FORMAT),
                    fullDay: true,
                };
            });
        },
        userMonthHalfWorkedDays() {
            return this.monthHalfWorkedDays.map((date) => {
                return {
                    date: moment(date).format(dateFormatConstants.DATE_FORMAT),
                    fullDay: false,
                };
            });
        },

        contractStartDate() {
            if (
                this.userTimeReportInfo.contractStartDate &&
                moment(this.userTimeReportInfo.contractStartDate).isBefore(
                    moment(utilsConstants.LAUNCH_DATE)
                )
            )
                return utilsConstants.LAUNCH_DATE;
            return this.userTimeReportInfo.contractStartDate;
        },
        contractEndDate() {
            if (
                this.userTimeReportInfo.contractEndDate &&
                moment(this.userTimeReportInfo.contractEndDate).isAfter(moment().add(3, 'years'))
            )
                return moment().add(3, 'years').format(dateFormatConstants.DATE_FORMAT);
            return this.userTimeReportInfo.contractEndDate;
        },

        formattedLastModificationDate() {
            return this.timeReport && this.timeReport.lastUserModificationDate
                ? moment(this.timeReport.lastUserModificationDate).format(
                      dateFormatConstants.DATE_TIME_FORMAT
                  )
                : null;
        },
    },

    methods: {
        async onYearMonthChange(yearMonth) {
            this.comingYearMonth = yearMonth;
            if (
                this.yearMonth &&
                this.yearMonth !== this.comingYearMonth &&
                this.isValidated &&
                this.isUpdating
            ) {
                this.displayDialog();
            } else {
                await this.loadYearMonthData(yearMonth);
            }
        },
        async onSaveActualTimeReport(yearMonth) {
            this.comingYearMonth = yearMonth;
            this.$emit('update:saveActualTimeReport', false);
            await this.loadYearMonthData(yearMonth);
            const periodRange = workedDaysService.getRangePeriod(yearMonth);
            const periodStartDate = periodRange.startDate;
            const periodEndDate = periodRange.endDate;
            this.saveTimeReport(periodStartDate, periodEndDate);
        },
        onShowOverview() {
            this.$emit('show-overview');
        },

        async goToComingYearMonth() {
            await this.loadYearMonthData(this.comingYearMonth);
            this.hideDialog();
        },
        async validateByDialog() {
            await this.updateOrSaveTimeReport();
            await this.loadYearMonthData(this.comingYearMonth);
            this.hideDialog();
        },
        async loadYearMonthData(yearMonth) {
            if (yearMonth.length === 4) {
                this.yearMonth = yearMonth;
            } else if (
                moment(yearMonth).isBefore(moment(this.userTimeReportInfo.periodStartDate)) ||
                moment(yearMonth).isAfter(moment(this.userTimeReportInfo.periodEndDate))
            ) {
                this.$emit('period-change', yearMonth);
            } else {
                this.yearMonth = yearMonth;

                const timeReport = await this.getTimeReportByUserGidAndYearMonth(
                    this.userGid,
                    yearMonth
                );
                const monthWorkingDays = this.getMonthWorkingDays(
                    this.userTimeReportInfo,
                    yearMonth
                );

                this.monthWorkingDays = monthWorkingDays;
                this.timeReport = timeReport;
                this.loadMonthWorkedDays();
            }
        },

        loadMonthWorkedDays() {
            if (this.timeReport && Array.isArray(this.timeReport.workedDays)) {
                const fullWorkedDays = [];
                const halfWorkedDays = [];
                this.timeReport.workedDays.forEach((data) => {
                    if (data.fullDay) {
                        fullWorkedDays.push(
                            moment(data.date).locale('fr').format(dateFormatConstants.DATE_FORMAT)
                        );
                    } else {
                        halfWorkedDays.push(
                            moment(data.date).locale('fr').format(dateFormatConstants.DATE_FORMAT)
                        );
                    }
                });

                this.monthWorkedDays = fullWorkedDays;
                this.monthHalfWorkedDays = halfWorkedDays;
            } else {
                this.monthWorkedDays = this.monthWorkingDays;
                this.monthHalfWorkedDays = [];
            }
        },

        getMonthWorkingDays(userTimeReportInfo, yearMonth) {
            if (
                userTimeReportInfo &&
                userTimeReportInfo.timeReportInfoArray &&
                userTimeReportInfo.timeReportInfoArray.length > 0
            ) {
                const timeReportInfo = userTimeReportInfo.timeReportInfoArray.find(
                    (timereportInfo) => timereportInfo.yearMonth === yearMonth
                );

                if (timeReportInfo) {
                    return timeReportInfo.monthWorkingDays;
                }
                return [];
            }
            return [];
        },
        async getTimeReportByUserGidAndYearMonth(userGid, yearMonth) {
            let timeReport = null;

            try {
                timeReport = await timeReportService.getTimeReportByUserGidAndYearMonth(
                    userGid,
                    yearMonth
                );
            } catch (e) {
                this.$header.addToast(
                    'error',
                    messagesConstants.GET_TIME_REPORT_FAILED.TITLE,
                    messagesConstants.GET_TIME_REPORT_FAILED.MESSAGE
                );
                throw e;
            }

            return timeReport;
        },

        async updateOrSaveTimeReport() {
            const periodRange = workedDaysService.getRangePeriod(this.yearMonth);
            const periodStartDate = periodRange.startDate;
            const periodEndDate = periodRange.endDate;

            if (!this.isValidated) {
                this.saveTimeReport(periodStartDate, periodEndDate);
                return;
            }

            try {
                this.timeReport.workedDays = this.userMonthWorkedDays;
                this.timeReport.totalWorkedDays = this.monthWorkedDaysNumber;
                this.timeReport = await timeReportService.updateTimeReport(
                    this.timeReport,
                    this.userGid
                );

                this.$emit('refresh-user-time-report-info');
                this.$store.dispatch('refreshUserTotalWorkedDays', {
                    userGid: this.userGid,
                    startPeriod: periodStartDate,
                    endPeriod: periodEndDate,
                });
            } catch (e) {
                this.$header.addToast(
                    'error',
                    messagesConstants.VALIDATION_FAILED.TITLE,
                    messagesConstants.VALIDATION_FAILED.toParamString(
                        this.buildYearMonthForToast(this.yearMonth)
                    )
                );
                throw e;
            }
            this.$header.addToast(
                'success',
                messagesConstants.UPDATE_SUCCESS.TITLE,
                messagesConstants.UPDATE_SUCCESS.toParamString(
                    this.buildYearMonthForToast(this.yearMonth)
                )
            );
        },
        async saveTimeReport(periodStartDate, periodEndDate) {
            if (this.isValidated) {
                this.$header.addToast(
                    'warning',
                    messagesConstants.VALIDATION_SUCCESS.TITLE,
                    messagesConstants.VALIDATION_ALREADY_VALIDATED.toParamString(
                        this.buildYearMonthForToast(this.yearMonth)
                    )
                );
                return;
            }

            try {
                this.timeReport = await timeReportService.createTimeReport(
                    this.userGid,
                    this.yearMonth,
                    this.userMonthWorkedDays,
                    this.monthWorkedDaysNumber
                );

                this.$emit('refresh-user-time-report-info');
                this.$store.dispatch('refreshUserTotalWorkedDays', {
                    userGid: this.userGid,
                    startPeriod: periodStartDate,
                    endPeriod: periodEndDate,
                });
            } catch (e) {
                this.$header.addToast(
                    'error',
                    messagesConstants.VALIDATION_FAILED.TITLE,
                    messagesConstants.VALIDATION_FAILED.toParamString(
                        this.buildYearMonthForToast(this.yearMonth)
                    )
                );
                throw e;
            }
            this.$header.addToast(
                'success',
                messagesConstants.VALIDATION_SUCCESS.TITLE,
                messagesConstants.VALIDATION_VALIDATED.toParamString(
                    this.buildYearMonthForToast(this.yearMonth)
                )
            );
        },

        buildYearMonthForToast(yearMonth) {
            const formattedYearMonth = moment(yearMonth, dateFormatConstants.YEAR_MONTH_FORMAT)
                .locale('fr')
                .format('MMMM YYYY');
            return utilsService.isFirstLetterVowel(formattedYearMonth)
                ? `d'${formattedYearMonth}`
                : `de ${formattedYearMonth}`;
        },
        displayDialog() {
            this.isDialogDisplayed = true;
        },
        hideDialog() {
            this.isDialogDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.calendar-view {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
