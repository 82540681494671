<template>
    <v-btn
        id="monthly-summary-item"
        class="mx-2"
        retain-focus-on-click
        depressed
        :class="{ 'worked-day-number-out-of-contract': isOutOfContract }"
        :color="getButtonColor()"
        @click="onClick()"
    >
        <v-icon dark right class="check-circle-icon" v-if="isValidated">mdi-check-circle</v-icon>
        <div class="month-label">
            {{ monthLabel }} ({{ monthWorkedDaysNumber }}/{{ monthWorkingDaysNumber }})
        </div>
        <v-icon dark right class="send-icon">mdi-send</v-icon>
    </v-btn>
</template>

<script>
import moment from 'moment';
import colors from '@/styles/colors.scss';
import utilsService from '@/services/utils.service';

export default {
    name: 'month-summary-item',

    props: {
        yearMonth: {
            type: String,
            required: true,
        },
        monthWorkedDaysNumber: {
            type: Number,
            required: false,
            default: 0,
        },
        monthWorkingDaysNumber: {
            type: Number,
            required: true,
        },
        isOutOfContract: {
            type: Boolean,
            required: false,
            default: false,
        },
        isValidated: {
            type: Boolean,
            required: false,
            default: false,
        },
        isMonthOverworked: {
            type: Boolean,
            required: false,
            default: false,
        },
        isMobilePlatform: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        monthLabel() {
            return utilsService.upperCaseFirstLetter(
                moment(this.yearMonth).locale('fr').format('MMM YYYY')
            );
        },
    },

    methods: {
        onClick() {
            this.$emit('year-month-change', this.yearMonth);
            if (this.isMobilePlatform) {
                this.$store.dispatch('hideMonthlySummary');
            }
        },

        getButtonColor() {
            if (this.isOutOfContract) {
                return colors.gray;
            }

            if (!this.isValidated) {
                return 'var(--v-accent-base)';
            }

            if (this.isMonthOverworked) {
                return colors.darkRed;
            }

            return 'var(--v-primary-darken2)';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
#monthly-summary-item {
    color: $white;
    width: 250px;
    border: none !important;
    .send-icon {
        position: absolute;
        right: 5px;
    }
    .check-circle-icon {
        padding-right: 15px;
    }
    .month-label {
        width: 160px;
    }

    &.worked-day-number-out-of-contract {
        cursor: not-allowed;
        pointer-events: none;
        border: 1px solid $dark-gray !important;
    }
}
</style>
