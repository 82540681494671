const MIN_WIDTH_WEB = 1141;

const LAUNCH_DATE = '2021-06-01';
const LAUNCH_YEAR_MONTH = '2021-06';

export const USER_ROLES = {
    MANAGER: 'manager',
    HR: 'hr',
    EXECUTIVE: 'executive',
};

export default {
    MIN_WIDTH_WEB,
    LAUNCH_DATE,
    LAUNCH_YEAR_MONTH,
    USER_ROLES,
};
