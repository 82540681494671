import moment from 'moment';
import dateFormatConstants from '@/constants/date-format.constants';
import geoZoneConstants from '@/constants/geographical-zone.constants';
import workedDaysService from '@/services/worked-days.service';

export default {
    buildUserTimeReportInfoArray,
    buildUserTimeReportInfo,
    buildTimeReportInfo,

    getTimeReportSummary,
    isInContract,
    isDateInYearMonth,
    isValidatedFromPeriodReportSummary,
    getMonthWorkedDaysNumber,
    isOverworked,
};

/**
 * Build user summary time report information list that contains users information and users information summary time report
 * @param {Array} userPeriodReportSummaryArray list of UserPeriodReportSummary Loaded from the database contains users information and summary of all months of the period
 * @param {String} periodStartDate the period start date
 * @param {String} periodEndDate the period end date
 */
async function buildUserTimeReportInfoArray(
    userPeriodReportSummaryArray,
    periodStartDate,
    periodEndDate
) {
    const metropolePublicHolidays = await workedDaysService.getPeriodPublicHolidays(
        periodStartDate,
        periodEndDate,
        geoZoneConstants.METROPOLE
    );
    const alsacePublicHolidays = await workedDaysService.getPeriodPublicHolidays(
        periodStartDate,
        periodEndDate,
        geoZoneConstants.ALSACE_MOSELLE
    );
    return Promise.all(
        userPeriodReportSummaryArray.map(async (userPeriodReportSummary) =>
            buildUserTimeReportInfo(
                userPeriodReportSummary,
                periodStartDate,
                periodEndDate,
                userPeriodReportSummary.zone === geoZoneConstants.ALSACE_MOSELLE
                    ? alsacePublicHolidays
                    : metropolePublicHolidays
            )
        )
    );
}

/**
 * Build user summary time report information that contains user information and user information summary time report information
 * @param {Object} userPeriodReportSummary Loaded from the database contains user information and summary of all months of the period
 * @param {String} periodStartDate the period start date
 * @param {String} periodEndDate the period end date
 * @param {String} receivedPublicHolidays
 */
async function buildUserTimeReportInfo(
    userPeriodReportSummary,
    periodStartDate,
    periodEndDate,
    receivedPublicHolidays = undefined
) {
    const timeReportSummaries = userPeriodReportSummary.timeReportSummaries;
    const periodDates = workedDaysService.getDatesBetweenTwoDates(
        periodStartDate,
        periodEndDate,
        'months',
        dateFormatConstants.YEAR_MONTH_FORMAT
    );

    const gid = userPeriodReportSummary.gid;
    const name = `${userPeriodReportSummary.lastName} ${userPeriodReportSummary.firstName}`;
    const contractStartDate = userPeriodReportSummary.startDate;
    const contractEndDate = userPeriodReportSummary.endDate;
    const zone = userPeriodReportSummary.zone;
    const flatRateWork = userPeriodReportSummary.flatRateWork;
    const totalWorkedDays = userPeriodReportSummary.totalWorkedDays;

    const publicHolidays =
        receivedPublicHolidays ||
        (await workedDaysService.getPeriodPublicHolidays(periodStartDate, periodEndDate, zone));
    const timeReportInfoArray = periodDates.map((yearMonth) =>
        buildTimeReportInfo(
            timeReportSummaries,
            yearMonth,
            contractStartDate,
            contractEndDate,
            publicHolidays
        )
    );

    return {
        gid,
        name,
        zone,
        contractStartDate,
        contractEndDate,
        periodStartDate,
        periodEndDate,
        flatRateWork,
        totalWorkedDays,
        publicHolidays,
        timeReportInfoArray,
    };
}

/**
 * Build summary time report information of user
 * @param {Array} periodReportSummaries List of time Report summary of a user loaded from the database of all months of the period
 * @param {String} yearMonth String contains month and year
 * @param {String} contractStartDate Start date of the user's contract
 * @param {String} contractEndDate End date of the user's contract
 * @param {String} publicHolidays
 */
function buildTimeReportInfo(
    timeReportSummaries,
    yearMonth,
    contractStartDate,
    contractEndDate,
    publicHolidays
) {
    const periodReportSummary = getTimeReportSummary(timeReportSummaries, yearMonth);
    const isOutOfContract = !isInContract(yearMonth, contractStartDate, contractEndDate);
    const isValidated = isValidatedFromPeriodReportSummary(periodReportSummary);
    const monthWorkedDaysNumber = getMonthWorkedDaysNumber(periodReportSummary);
    const monthWorkingDays = getMonthWorkingDaysHavingPublicHolidays(
        publicHolidays,
        yearMonth,
        contractStartDate,
        contractEndDate
    );
    const isMonthOverworked = isOverworked(monthWorkedDaysNumber, monthWorkingDays.length);

    return {
        yearMonth,
        isOutOfContract,
        isValidated,
        monthWorkedDaysNumber,
        monthWorkingDays,
        isMonthOverworked,
    };
}

/**
 * Return time report summary which corresponds to the year-month sent as an input parameter
 * @param {Array} periodReportSummaries List of time Report summary of a user loaded from the database of all months of the period
 * @param {String} yearMonth String contains month and year
 */
function getTimeReportSummary(timeReportSummaries, yearMonth) {
    if (timeReportSummaries && timeReportSummaries.length > 0) {
        return timeReportSummaries.find((el) => {
            return el.yearMonth === yearMonth;
        });
    }
    return undefined;
}

/**
 * Checks if the month is included in the user's contract
 * @param {String} yearMonth String contains month and year
 * @param {String} contractStartDate Start date of the user's contract
 * @param {String} contractEndDate End date of the user's contract
 */
function isInContract(yearMonth, contractStartDate, contractEndDate) {
    const isAfterContractStartDate = moment(yearMonth).isSameOrAfter(
        moment(contractStartDate).format('YYYY-MM')
    );

    if (!contractEndDate) {
        return isAfterContractStartDate;
    }

    const isBeforeContractEndDate = moment(yearMonth).isBefore(moment(contractEndDate));
    return isAfterContractStartDate && isBeforeContractEndDate;
}

/**
 * check if the Time report summary is validated
 * @param {Object} periodReportSummary The summary time report for the user for a given month
 */
function isValidatedFromPeriodReportSummary(periodReportSummary) {
    return !!(periodReportSummary && periodReportSummary.numberOfWorkedDays !== undefined);
}

/**
 * Return true if the given date is included in the given year-month
 * @param yearMonth
 * @param date
 * @returns {boolean}
 */
function isDateInYearMonth(yearMonth, date) {
    if (!date) {
        return false;
    }

    return moment(yearMonth).isSame(moment(date).format('YYYY-MM'));
}

/**
 * Get number of days worked for a month
 * @param {Object} periodReportSummary The summary time report for the user for a given month
 */
function getMonthWorkedDaysNumber(periodReportSummary) {
    if (periodReportSummary && periodReportSummary.numberOfWorkedDays !== undefined) {
        return periodReportSummary.numberOfWorkedDays;
    }
    return 0;
}

/**
 * return array of month working days
 * @param {Array} publicHolidays array of public holidays
 * @param {String} yearMonth String contains month and year
 * @param {String} contractStartDate Start date of the user's contract
 * @param {String} contractEndDate End date of the user's contract
 */
function getMonthWorkingDaysHavingPublicHolidays(
    publicHolidays,
    yearMonth,
    contractStartDate,
    contractEndDate
) {
    if (isDateInYearMonth(yearMonth, contractStartDate)) {
        return workedDaysService.getMonthWorkingDaysHavingPublicHolidays(
            contractStartDate,
            publicHolidays,
            true,
            false
        );
    }

    if (isDateInYearMonth(yearMonth, contractEndDate)) {
        return workedDaysService.getMonthWorkingDaysHavingPublicHolidays(
            contractEndDate,
            publicHolidays,
            false,
            true
        );
    }

    return workedDaysService.getMonthWorkingDaysHavingPublicHolidays(yearMonth, publicHolidays);
}

/**
 * Check if the month is overworked
 * @param {Number} monthWorkedDaysNumber Number of days worked for a given month
 * @param {Number} monthWorkingDaysNumber Number of days to work for  given a month
 */
function isOverworked(monthWorkedDaysNumber, monthWorkingDaysNumber) {
    return monthWorkedDaysNumber > monthWorkingDaysNumber;
}
