<template>
    <div>
        <v-overlay v-if="isLoaderDisplayed" :value="true" :absolue="true">
            <v-progress-circular
                size="100"
                width="15"
                indeterminate
                color="primary"
                class="loader"
            />
        </v-overlay>

        <worked-days-calendar
            v-else
            :readOnly="readOnly"
            :pageSpecificDetails="pageSpecificDetails"
            :saveActualTimeReport.sync="saveActualTimeReport"
            :receivedYearMonth="yearMonth"
            :userTimeReportInfo="userTimeReportInfo"
            @period-change="onPeriodChange"
            @refresh-user-time-report-info="onRefreshUserTimeReportInfo()"
            @show-overview="onShowOverview()"
        />
    </div>
</template>

<script>
import workedDaysService from '@/services/worked-days.service';
import periodReportSummaryApiService from '@/services/period-report-summary-api.service';
import periodReportSummaryService from '@/services/period-report-summary.service';
import WorkedDaysCalendar from '@/components/calendar/worked-days-calendar';
import messagesConstants from '@/constants/messages.constants';
import { USER_ROLES } from '@/constants/utils.constants';

export default {
    name: 'time-report-details',

    components: {
        WorkedDaysCalendar,
    },

    props: {
        readOnly: {
            type: Boolean,
            required: true,
        },
        pageSpecificDetails: {
            type: Object,
            required: true,
        },
        receivedYearMonth: {
            type: String,
            required: true,
        },

        userGid: {
            type: String,
            required: false,
            default: undefined,
        },
        receivedUserTimeReportInfo: {
            type: Object,
            required: false,
            default: undefined,
        },
        receivedSaveActualTimeReport: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            yearMonth: undefined,
            userTimeReportInfo: undefined,

            saveActualTimeReport: this.receivedSaveActualTimeReport,
            isLoaderDisplayed: false,
        };
    },

    created() {
        this.$store.dispatch('monthlySummaryCanBeHided');
        if (this.receivedUserTimeReportInfo) {
            this.yearMonth = this.receivedYearMonth;
            this.userTimeReportInfo = this.receivedUserTimeReportInfo;
        } else {
            this.onPeriodChange(this.receivedYearMonth, this.userGid);
        }
    },

    watch: {
        async saveActualTimeReport(newVal) {
            this.saveActualTimeReport = newVal;
            this.$emit('update:receivedSaveActualTimeReport', newVal);
        },
    },

    methods: {
        async onPeriodChange(yearMonth, userGid = this.userTimeReportInfo.gid) {
            this.displayLoader();

            const periodRange = workedDaysService.getRangePeriod(yearMonth);
            const periodStartDate = periodRange.startDate;
            const periodEndDate = periodRange.endDate;

            if (this.pageSpecificDetails.role === USER_ROLES.EXECUTIVE) {
                await this.$store.dispatch('refreshUserFlatRateWork', {
                    userGid,
                    startPeriod: periodStartDate,
                    endPeriod: periodEndDate,
                    vm: this,
                });

                await this.$store.dispatch('refreshUserTotalWorkedDays', {
                    userGid: this.userGid,
                    startPeriod: periodStartDate,
                    endPeriod: periodEndDate,
                });
            }
            await this.loadInfosByUserGid(yearMonth, userGid, periodStartDate, periodEndDate);

            this.hideLoader();
        },
        onRefreshUserTimeReportInfo() {
            const yearMonth = this.yearMonth;
            const userGid = this.userTimeReportInfo.gid;
            const periodStartDate = this.userTimeReportInfo.periodStartDate;
            const periodEndDate = this.userTimeReportInfo.periodEndDate;
            this.loadInfosByUserGid(yearMonth, userGid, periodStartDate, periodEndDate);
        },
        onShowOverview() {
            this.$emit('show-overview');
        },

        async loadInfosByUserGid(yearMonth, userGid, periodStartDate, periodEndDate) {
            const userPeriodReportSummary = await this.getUserPeriodReportSummary(
                userGid,
                periodStartDate,
                periodEndDate
            );
            const userTimeReportInfo = await this.getUserTimeReportInfo(
                userPeriodReportSummary,
                periodStartDate,
                periodEndDate
            );

            this.yearMonth = yearMonth;
            this.userTimeReportInfo = userTimeReportInfo;
        },

        async getUserTimeReportInfo(userPeriodReportSummary, periodStartDate, periodEndDate) {
            try {
                const userTimeReportInfo = await periodReportSummaryService.buildUserTimeReportInfo(
                    userPeriodReportSummary,
                    periodStartDate,
                    periodEndDate
                );
                return userTimeReportInfo;
            } catch (e) {
                this.hideLoader();
                this.$header.addToast(
                    'error',
                    messagesConstants.GET_PUBLIC_HOLIDAYS_FAILED.TITLE,
                    messagesConstants.GET_PUBLIC_HOLIDAYS_FAILED.MESSAGE
                );
                throw e;
            }
        },
        async getUserPeriodReportSummary(userGid, periodStartDate, periodEndDate) {
            try {
                return await periodReportSummaryApiService.getUserPeriodReportSummary(
                    userGid,
                    periodStartDate,
                    periodEndDate
                );
            } catch (e) {
                this.hideLoader();
                this.$header.addToast(
                    'error',
                    messagesConstants.GET_TIME_REPORT_SUMMARY_FAILED.TITLE,
                    messagesConstants.GET_TIME_REPORT_SUMMARY_FAILED.toParamString(userGid)
                );
                throw e;
            }
        },

        displayLoader() {
            this.isLoaderDisplayed = true;
        },
        hideLoader() {
            this.isLoaderDisplayed = false;
        },
    },
};
</script>
