<template>
    <v-navigation-drawer
        id="monthly-summary"
        stateless
        v-model="$store.state.isMonthlySummaryDisplayed"
        width="320"
        absolute
        bottom
        :class="{ 'mobile-plateform': isMobilePlatform }"
    >
        <div class="monthly-summary-title">
            <div class="annual-counter mb-2">
                <div class="label">Compteur annuel de</div>
                <div class="name">{{ userTimeReportInfo.name }}</div>
                <div class="count">
                    {{ userTimeReportInfo.totalWorkedDays }} /
                    {{
                        userTimeReportInfo.flatRateWork >= 0
                            ? `${userTimeReportInfo.flatRateWork}`
                            : 'information manquante'
                    }}
                </div>
            </div>

            <div>
                <div>Résumé mensuel</div>
                <div>{{ title }}</div>
            </div>
        </div>

        <v-list nav dense>
            <v-list-item-group
                dark
                v-if="userTimeReportInfo && userTimeReportInfo.timeReportInfoArray"
            >
                <v-list-item
                    v-for="(timeReportInfo, i) in userTimeReportInfo.timeReportInfoArray"
                    :key="i"
                >
                    <v-list-item-content>
                        <month-summary-item
                            :yearMonth="timeReportInfo.yearMonth"
                            :monthWorkedDaysNumber="timeReportInfo.monthWorkedDaysNumber"
                            :monthWorkingDaysNumber="timeReportInfo.monthWorkingDays.length"
                            :isOutOfContract="timeReportInfo.isOutOfContract"
                            :isValidated="timeReportInfo.isValidated"
                            :isMonthOverworked="timeReportInfo.isMonthOverworked"
                            :isMobilePlatform="isMobilePlatform"
                            @year-month-change="onYearMonthChange"
                        />
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import moment from 'moment';
import utilsConstants from '@/constants/utils.constants';
import MonthSummaryItem from '@/components/calendar/month-summary-item';

export default {
    name: 'month-summary',

    components: {
        MonthSummaryItem,
    },

    props: {
        userTimeReportInfo: {
            type: Object,
            required: true,
        },
    },

    computed: {
        title() {
            return `période du ${moment(this.userTimeReportInfo.periodStartDate).format(
                'DD/MM/YYYY'
            )} au 31/${moment(this.userTimeReportInfo.periodEndDate).format('MM/YYYY')}`;
        },
        isMobilePlatform() {
            return this.$store.state.isMobilePlatform;
        },
    },

    created() {
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },

    methods: {
        onYearMonthChange(yearMonthReceived) {
            this.$emit('year-month-change', yearMonthReceived);
        },
        onResize() {
            const isMobilePlatform = window.innerWidth < utilsConstants.MIN_WIDTH_WEB;
            if (isMobilePlatform !== this.$store.state.isMobilePlatform) {
                this.$store.dispatch('onScreenResizeAction', isMobilePlatform);
            }
        },
    },
};
</script>

<style lang="scss">
#monthly-summary {
    background: linear-gradient(70deg, var(--v-info-darken2), var(--v-primary-lighten1));

    .monthly-summary-title {
        color: white;
        text-align: center;
        padding: 10px;

        .annual-counter {
            border: 2px solid;
            border-radius: 10px;

            .label {
                font-size: 0.875em;
            }
            .name {
                font-size: 1em;
            }
            .count {
                font-size: 1.125em;
                font-weight: bold;
            }
        }
    }

    .v-navigation-drawer__content {
        margin-bottom: 30px;
        .v-list-item__content {
            padding: 5px 0;
        }
        .v-list-item-group .v-list-item--active {
            color: white;
        }
        .v-list-item {
            padding: 0 10px;
            margin: 0 5px;
            text-align: center;
        }
        .v-list-item--link {
            cursor: initial;
            pointer-events: initial;
            border: initial;
        }
    }

    &.mobile-plateform {
        width: 100% !important;
        background: rgba(0, 0, 0, 0.8) !important;
        margin-top: 2px;
    }
}
</style>
