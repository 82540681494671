<template>
    <div class="calendar-view">
        <v-btn
            class="mx-2 without-border"
            color="primary"
            outlined
            medium
            v-if="readOnly"
            @click="showOverview()"
        >
            <v-icon dark>mdi-arrow-left</v-icon>
            {{ showOverviewTitle }}
        </v-btn>
        <div class="worked-days-headband">
            <div
                class="total-worked-days"
                :class="{
                    'is-validated': isValidated,
                    'is-over-working': isOverWorking,
                    'is-updating': isUpdating,
                }"
            >
                <span>
                    <v-icon v-if="isOverWorking" size="20">mdi-calendar-alert</v-icon>
                    <v-icon v-else-if="isValidated && !isUpdating" size="20">
                        mdi-calendar-check
                    </v-icon>
                </span>

                <span>
                    Présence totale
                    <span v-if="readOnly">de {{ name }}</span>
                    :
                    <span class="total-worked-days-result">
                        <span v-if="monthWorkingDaysNumber">
                            {{ monthWorkedDaysNumber }}/{{ monthWorkingDaysNumber }} jours
                        </span>
                        <span v-else>NC</span>
                    </span>
                </span>
            </div>
        </div>

        <slot />

        <span class="validation-date" v-if="formattedLastModificationDate">
            Dernière validation le {{ formattedLastModificationDate }}
        </span>

        <span class="center-button" v-if="!readOnly">
            <v-btn
                color="primary"
                @click="updateOrSaveTimeReport()"
                :disabled="isValidated && !isUpdating"
            >
                Valider
            </v-btn>
        </span>
        <span class="month-not-validated" v-else-if="readOnly && !isValidated && name">
            <v-icon>mdi-alert-circle</v-icon>
            Ce mois n'a pas été validé par {{ name }}.
        </span>
    </div>
</template>

<script>
import { USER_ROLES } from '@/constants/utils.constants';

export default {
    name: 'calendar-wrapper',

    props: {
        name: {
            type: String,
            required: false,
            default: '',
        },
        isValidated: {
            type: Boolean,
            required: false,
            default: false,
        },
        isOverWorking: {
            type: Boolean,
            required: false,
            default: false,
        },
        isUpdating: {
            type: Boolean,
            required: false,
            default: false,
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        role: {
            type: String,
            required: false,
            default: '',
        },
        monthWorkingDaysNumber: {
            type: Number,
            required: false,
            default: 0,
        },
        monthWorkedDaysNumber: {
            type: Number,
            required: false,
            default: 0,
        },
        formattedLastModificationDate: {
            type: String,
            required: false,
            default: '',
        },
    },

    computed: {
        showOverviewTitle() {
            if (this.role === USER_ROLES.HR) {
                return 'Retour à la vue Ressources Humaines';
            }
            if (this.role === USER_ROLES.MANAGER) {
                return 'Retour à la vue Manager';
            }
            return 'Retour';
        },
    },

    methods: {
        updateOrSaveTimeReport() {
            this.$emit('updateOrSaveTimeReport');
        },
        showOverview() {
            this.$emit('show-overview');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
.calendar-view {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .without-border {
        border: none;
    }

    .navigation-btn {
        position: absolute;
        left: 25%;
        top: 2%;
    }

    .worked-days-headband {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .total-worked-days {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border-radius: 5px;
            font-size: 1.1em;

            .total-worked-days-result {
                margin-left: 4px;
                font-weight: bold;
            }

            .v-icon {
                margin-right: 5px;
            }

            &.is-validated {
                background: var(--v-primary-base);
                color: $white;
                .v-icon {
                    color: $white;
                }
            }

            &.is-updating,
            .is-validated.is-updating {
                background: $white;
                color: inherit;
                .v-icon {
                    color: inherit;
                }
            }

            &.is-over-working,
            .is-over-working.is-updating,
            .is-validated.is-over-working.is-updating {
                background: $white;
                color: $dark-red;
                .v-icon {
                    color: $dark-red;
                }
            }

            &:not(.is-updating).is-over-working.is-validated {
                background: $dark-red;
                color: $white;
                .v-icon {
                    color: $white;
                }
            }
        }
    }

    .validation-date {
        font-size: 0.8em;
    }

    .month-not-validated {
        background: var(--v-accent-lighten3);
        font-weight: bold;
        border-radius: 5px;
        padding: 8px;
        margin-top: 10px;
        font-size: 0.8em;
    }

    .center-button {
        text-align: center;
        margin: 0 auto;
        display: block;
        padding: 20px;
    }
}
</style>
